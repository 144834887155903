import React from "react"
import Layout from "../components/layout"

const Success = () => {
  return (
    <Layout
      type="success"
      title="Congratulations!"
      subtitle="Your guide to boosting your website conversions is heading to your mailbox. Keep an eye on your inbox as you'll hear from us shortly!"
    ></Layout>
  )
}

export default Success
